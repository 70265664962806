var $ = require('jQuery');

var App = {
    init: function () {
        $(window).scroll(function () {
            if ($(".navbar").offset().top > 50) {
                $(".navbar-fixed-top").removeClass("navbar-home");
                $(".navbar-fixed-top").addClass("navbar-content");
            } else {
                $(".navbar-fixed-top").addClass("navbar-home");
                $(".navbar-fixed-top").removeClass("navbar-content");
            }
        });

        $(document).ready(function() {
            $('a.page-scroll').bind('click', function (e) {
                var $anchor = $(this);

                $('html,body').stop().animate(
                    {
                        scrollTop: $($anchor.attr('href')).offset().top - 65.59
                    },
                    1000,
                    'swing'
                );
            });

            $('#stackedNav').bind('click', function (e) {
               $('#stackedNav').removeClass("in");
            });
        });
    }
};

module.exports = App;